









































import { Component, Vue } from 'vue-property-decorator'
import { namespace, Action } from 'vuex-class'
import { Route } from 'vue-router'
import {
  getModule
} from 'vuex-module-decorators'
const domainSettingNS = namespace('domainSetting')
@Component({

})
export default class DomainSetting extends Vue {

	private loaded: boolean = false

	private saving: boolean = false

	private projectId: string = ''

	private querying: Boolean = false

	@domainSettingNS.State
	private domainSetting: any

	@domainSettingNS.Action
	public fetchDomainSetting!: (projectId: string) => Promise<any>
	@domainSettingNS.Action
	public submitDomainSetting!: (payload: any) => Promise<any>

	get requestDomainList () {
		if (this.domainSetting && this.domainSetting.requestdomain.length > 0) {
			return this.domainSetting.requestdomain.map((item: string) => ({url: item}))
		}
	}

	public addRequestDomain () {
		this.domainSetting.requestdomain.push('')
	}

	public async tapQuery () {
		(this.$refs.form as any).validate().then((res: Boolean) => {
			this.querying = true
			if (res) {
				this.fetchDomainSetting(this.projectId).then(() => {
					console.log(this.domainSetting)
					if (this.domainSetting.requestdomain.length == 0) {
						this.addRequestDomain()
					}
					this.querying = true
					this.loaded = true
				})
			}
		})
	}

	public async tapSubmit () {
		this.saving = true
		const params: any = {
			requestdomain: this.requestDomainList.map((item: any) => item.url),
			uploaddomain: this.domainSetting.uploaddomain
		}
		this.submitDomainSetting({params, projectId: this.projectId}).then(() => {
			this.saving = false
			this.$toast.success('Save successfully', {decuration: 1000})
		})
	}

	mounted () {
		this.projectId = this.domainSetting.projectId = (this.$route.query.projectId as string) || ''
		this.$nextTick(() => {
			if (this.projectId) {
				this.tapQuery()
			}
		})
	}
}
